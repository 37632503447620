import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../config/axiosApi';
import { showToast } from './toastSlice';

const initialState = {
  token: localStorage.getItem('token') || null,
  isAuthenticated: localStorage.getItem('token') ? true : false,
  isAdmin: localStorage.getItem('isAdmin')
    ? JSON.parse(localStorage.getItem('isAdmin'))
    : false,
  loading: false,
  error: null,
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
};

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password, project }, { rejectWithValue, dispatch }) => {
    try {
      console.log('credentials', email, password);
      const response = await axios.post('/login', { email, password, project });

      dispatch(setAdmin(response.data.isAdmin));
      dispatch(setToken(response.data.token));
      if (!response.data.isAdmin) dispatch(setUser(response.data.user));
      dispatch(showToast('Login successful'));

      return response.data.token;
    } catch (error) {
      console.log(error);
      dispatch(showToast(error.response.data.message));
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      state.isAuthenticated = true;
      localStorage.setItem('token', action.payload);
    },
    setUser(state, action) {
      console.log('useraction', action.payload);
      state.user = action.payload;

      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    setAdmin(state, action) {
      state.isAdmin = action.payload;
      localStorage.setItem('isAdmin', JSON.stringify(action.payload));
    },
    logout(state) {
      state.token = null;
      state.isAuthenticated = false;
      state.isAdmin = false;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('isAdmin');
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});

export const { login, logout, setToken, setUser, setAdmin } = authSlice.actions;

export default authSlice.reducer;
